import '../styles/Fonts.global.css';
import '../styles/reset.css';
import '../styles/globals.scss';
import '../styles/Columns.global.scss';
import '../styles/Nav.global.scss';
import '../styles/Carousel.global.scss';
import Script from 'next/script';
import themes from '../styles/Themes.module.scss';
import React from 'react';
import type { AppProps } from 'next/app';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Head from 'next/head';
import { fetchAPI } from '../strapi/api';
import NextNProgress from 'nextjs-progressbar';
import getConfig from "next/config";
import Router from 'next/router';
const { publicRuntimeConfig } = getConfig();

const websiteInfo = {
  title: 'Kayhan Space',
  url: publicRuntimeConfig.NEXT_PUBLIC_SITE_URL,
  description: `Kayhan Space is on a mission to bring next-generation automation to satellite operations and spaceflight safety.`
};

export type ThemeContextType = {
  modeClassName: string;
  setLockScroll: (bool: boolean) => void;
};

export type ScrollPosContextType = {
  scrollPos: number;
};

export type MenuStateContextType = {
  menuOpened: boolean;
  setMenuOpened: (bool: boolean) => void;
};

export const ThemeContext = React.createContext<ThemeContextType | null>(null);
export const ScrollPosContext = React.createContext<ScrollPosContextType | null>(null);
export const MenuStateContext = React.createContext<MenuStateContextType | null>(null);

const asciiArt = String.raw
  `        _..._
      .'_    '.      _
     / // .-""-\   _/ \
   .-| . /:.   |  |   |
   |  \  |:.   /.-'-./
   | .-'-;:__.'    =/
   .'=  *=|KAYHAN.='
  /   _.  | __ ;
 ;-.-'|    \   |
/   | \    _\  _\
\__/'._;.  ==' ==\
         \    \   |
         /    /   /
         /-._/-._/
         \   '\  \
          '-._/._/

🌌 Hello there! 🌌
🚀 Want to work on cool space stuff? 🚀
👉 Go fill out https://kayhanspace.typeform.com/to/qJPYrgkB 👈 
`;

export interface NavigationSection {
  id: number,
  Title: string,
  Slug: string | null,
  FooterOnly?: boolean,
  NavItem: {
    id: number,
    Title: string,
    Slug: string,
    Description: string | null,
    FooterOnly?: boolean
    IsDynamicPage?: boolean
  }[]
}

interface Props extends AppProps {
  navigation: NavigationSection[],
  mainCta: {
    Title: string,
    URL: string
  }
}

function KayhanSpace({ Component, pageProps, navigation, mainCta }: Props) {
  const [menuOpened, setMenuOpened] = React.useState(false);
  const [modeClassName, setModeClassName] = React.useState('light');
  const [scrollPos, setScrollPos] = React.useState(0);
  const [lockScroll, setLockScroll] = React.useState(false);
  const [consoleLogDisplayed, setConsoleLogDisplayed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    setScrollPos(winScroll);
  };

  const handleRouteChangeStart = () => {
    setIsLoading(true);
  };

  const handleRouteChangeComplete = () => {
    setIsLoading(false);
  };

  const handleRouteChangeError = () => {
    Router.push('/404');
  };

  React.useEffect(() => {
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    Router.events.on("routeChangeError", handleRouteChangeError);
    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      Router.events.off("routeChangeError", handleRouteChangeError);
    }
  }, []);

  React.useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!consoleLogDisplayed) {
      console.log(asciiArt);
      setConsoleLogDisplayed(true);
    }
  }, [consoleLogDisplayed])

  React.useEffect(() => {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    matchMedia.addEventListener('change', e => setModeClassName(e.matches ? 'dark' : 'light'));
    matchMedia.matches ? setModeClassName('dark') : setModeClassName('light');
  }, []);

  React.useEffect(() => {
    document.body.className = `${themes[modeClassName]} master-container${lockScroll ? ' lock-scroll' : ''}`;
  }, [modeClassName, lockScroll]);

  return (
    <>
      <Script id="google-analytics-gtag" strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.NEXT_PUBLIC_GA}`} />
      <Script id="google-analytics-datalayer" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag( 'config', '${publicRuntimeConfig.NEXT_PUBLIC_GA}', {
              page_path: window.location.pathname,
            });
            gtag( 'config', '${publicRuntimeConfig.NEXT_PUBLIC_GA_C}');
        `}
      </Script>
      <Script id="google-analytics-conversion" strategy="lazyOnload">
        {`
            function gtag_report_conversion(url, isNewTab) {
              let callback = function () {
                if (typeof(url) !== 'undefined' && !isNewTab) {
                  window.location = url;
                }
              };
              gtag('event', 'conversion', {
                  'send_to': '${publicRuntimeConfig.NEXT_PUBLIC_GA_C}/${publicRuntimeConfig.NEXT_PUBLIC_GA_C_SUB}',
                  'event_callback': callback
              });
              return false;
            }
        `}
      </Script>

      <Head>
        <link rel="icon" href="/favicon.ico" key="favicon" />
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
        <meta property="og:type" content="website" key="ogtype" />
        <title key="title">{websiteInfo.title}</title>
        <link rel="canonical" href={websiteInfo.url} key="canonical" />
        <meta name="twitter:title" content={websiteInfo.title} key="twname" />
        <meta property="og:title" content={websiteInfo.title} key="ogtitle" />
        <meta name="description" content={websiteInfo.description} key="desc" />
        <meta name="og:description" content={websiteInfo.description} key="ogdesc" />
        <meta name="twitter:description" content={websiteInfo.description} key="twdesc" />
        <meta property="og:url" content={websiteInfo.url} key="ogurl" />
        <meta property="og:image" content={`${websiteInfo.url}/og.png`} key="ogimg" />
        <meta name="twitter:image" content={`${websiteInfo.url}/og.png`} key="twimg" />
        <meta name="twitter:card" content="summary_large_image" key="twlrgimg" />
        <link rel="apple-touch-icon" href="/logo192.png" key="iosicon" />
        <link rel="manifest" href="/site.webmanifest" charSet="utf-8" key="manifest" />
        {
          modeClassName !== 'dark' ?
            <meta name="theme-color" content="#F4F4F4" key="themecolor" /> :
            <meta name="theme-color" content="#262626" key="themecolor" />
        }
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      </Head>
      
      <MenuStateContext.Provider value={{ menuOpened, setMenuOpened }}>
        <ScrollPosContext.Provider value={{ scrollPos }}>
          <Header
            setModeClassName={setModeClassName}
            modeClassName={modeClassName}
            navigation={navigation}
            ctaUrl={mainCta ? mainCta.URL : ''}
            ctaTitle={mainCta ? mainCta.Title : ''}
          />
        </ScrollPosContext.Provider>

        <ThemeContext.Provider value={{ modeClassName, setLockScroll }}> 
          <NextNProgress
            color="#7D48ED"
            startPosition={0.3}
            stopDelayMs={200}
            height={4}
            showOnShallow={true}
          />
          <div className={`main-page-wrapper ${isLoading ? 'wrapper-loading' : ''}`}>
            <Component {...pageProps} />
          </div>
        </ThemeContext.Provider>
      </MenuStateContext.Provider>
      <Footer navigation={navigation} />
    </>
  )
};

KayhanSpace.getInitialProps = async () => {
    const navUrl = '/navs?populate=deep';
    const navigation = await fetchAPI(navUrl);
    const cta = await fetchAPI(`/main-cta`);

    return {
      navigation: navigation && navigation.data && navigation.data[0] ? navigation.data[0].attributes.NavSection : null,
      mainCta: {
        Title: cta && cta.data && cta.data.attributes && cta.data.attributes.Title ? cta.data.attributes.Title : null,
        URL: cta && cta.data && cta.data.attributes && cta.data.attributes.URL ? cta.data.attributes.URL : null
      }
    }
};

export default KayhanSpace;
