import React from "react";
import NewTabIcon from "./assets/new-tab.svg";
import DownloadIcon from "./assets/download.svg";
import Link from "next/link";
import { getStrapiURL } from "../strapi/api";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export enum LINK_TYPES {
    NEW_TAB,
    NEW_TAB_NO_ICON,
    NORMAL,
}

interface Props {
    text?: string;
    trackConversion?: boolean;
    classNames?: string;
    href: string;
    customStyles?: boolean;
    children?: JSX.Element;
    onClick?: Function;
    label?: string;
    noFollow?: boolean;
    isDownload?: boolean;
}

const LinkWrapper: React.FC<Props> = (props) => {
    let classNames = props.customStyles ? '' : 'link';
    let isInternalLink = false;
    let isDownload = false;

    if (props.href.indexOf('./') === 0 || props.href.indexOf('/') === 0) {
        isInternalLink = true;
    }

    if (props.href.indexOf('.css') > -1 || props.href.indexOf('.zip') > -1) {
        isDownload = true;
    }

    let baseUrl = publicRuntimeConfig.NEXT_PUBLIC_SITE_URL;
    if (baseUrl) {
        baseUrl = baseUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    }

    let href = props.href.indexOf('uploads/') > -1 && props.href.indexOf(`${baseUrl}/`) ? getStrapiURL(props.href) : props.href;

    if (props.classNames) classNames += ` ${props.classNames}`;
    
    return (
        <>
        {
            !isInternalLink ? 
                    <a
                        href={href}
                        rel={`noopener noreferrer${props.noFollow ? ' nofollow' : ''}`}
                        target={'_blank'}
                        className={classNames}
                        aria-label={props.label}
                        title={props.label}
                        download={isDownload}
                        onClick={(e) => {
                            if (props.onClick) {
                                if (props.onClick.length > 0) {
                                    props.onClick(e);
                                } else {
                                    props.onClick();
                                }
                            }
                            if (props.trackConversion) {
                                //@ts-expect-error typescript error for running imported google analytics script in _app
                                if (gtag_report_conversion) {
                                    //@ts-expect-error same error as above
                                    gtag_report_conversion(href, !isInternalLink);
                                }
                            }
                        }}
                    >
                        {
                            props.children ? props.children :
                            <>
                                <span>{props.text}</span>
                                {isDownload ? <DownloadIcon /> : <NewTabIcon />}
                            </>
                        }
                    </a> : 
                    <Link
                        href={href}
                    >
                        <a className={classNames}>
                            {
                                props.children ? props.children :
                                <>
                                    <span>{props.text}</span>
                                    {isDownload ? <DownloadIcon /> : null}
                                </>
                            }
                        </a>
                    </Link>
        }
        </>
    )
};

export default LinkWrapper;