import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import styles from './Typer.module.scss';

interface Props {
    text: string;
    delay?: number;
    vizTrigger?: boolean;
}

const Typer: React.FC<Props> = (props) => {
    const [flagged, setFlagged] = React.useState(false);
    const [typewriterStore, setTypewriterStore] = React.useState<TypewriterClass | null>();
    const [isComplete, setIsComplete] = React.useState(false);
    const timing = props.text ? Math.round(350 / props.text.length) : 30;
    const typewriterRef = React.useRef(<Typewriter
        options={{
            delay: props.delay ? props.delay : timing,
            cursor: ''
        }}
        onInit={(typewriter) => {
            setTypewriterStore(typewriter);
            if(props.vizTrigger){
                typewriter.typeString(props.text).callFunction(() => { setIsComplete(true) });
            } else {
                typewriter.typeString(props.text).callFunction(() => { setIsComplete(true) }).start();
            }
            
        }}
    />);

    React.useEffect(() => {
        if(typewriterStore && flagged){
            setFlagged(true);
            typewriterStore.start();
        }
    }, [typewriterStore, flagged])

    const className = `${styles['typer-container']} ${!isComplete ? styles['typing'] : ''}`;
    return (
        props.vizTrigger ? 
            <VisibilitySensor
                onChange={(isVisible: boolean) => {
                    if (isVisible && !flagged) {
                        setFlagged(true);
                    }
                }
            }>  
                <div className={className}>
                    {typewriterRef.current}
                <div className={styles.ghost}>
                    {props.text}
                </div>
            </div>
        </VisibilitySensor> :
            <div className={className}>
                {typewriterRef.current}
                <div className={styles.ghost}>
                    {props.text}
                </div>
            </div>
    )
}
export default Typer;