import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styles from "./Splitter.module.scss";

interface Props {
    classNames?: string;
    vizTrigger?: boolean;
    isThick?: boolean;
    text?: string;
    heroMargin?: boolean;
    hideOnMobile?: boolean;
}

const Splitter: React.FC<Props> = (props) => {
    let classNamesInit = styles['splitter'];
    if (props.hideOnMobile) classNamesInit += ` ${styles['hide-on-mobile']}`;
    if (props.classNames) classNamesInit += ` ${props.classNames}`;
    if (props.vizTrigger) classNamesInit += ` ${styles['animated']}`;
    if (props.isThick) classNamesInit += ` ${styles['thick']}`;
    if (props.text) classNamesInit += ` ${styles['text']}`;
    if (props.heroMargin) classNamesInit += ` ${styles['hero-margin']}`;
    const [classNames, setClassNames] = React.useState(classNamesInit);

    return(
        props.vizTrigger ? 
        <VisibilitySensor
            onChange={(isVisible: boolean) => {
                if(classNames.indexOf('active') === -1 && isVisible) setClassNames(`${classNames} ${styles['active']}`);
            }}
            ><div className={styles['splitter-container']}>
                    {props.text ? <span className={props.heroMargin ? styles['hero-margin'] : ''}>{props.text}</span> : null}
            <div className={classNames} />
        </div>
        </VisibilitySensor>
        :
            <div className={styles['splitter-container']}>
                {props.text ? <span className={props.heroMargin ? styles['hero-margin'] : ''}>{props.text}</span> : null}
            <div className={classNames} />
        </div>
    )
}
export default Splitter;