import styles from './Menu.module.scss';
import { CSSTransition } from "react-transition-group";
import Typer from './Typer';
import Splitter from './Splitter';
import { NavigationSection } from '../pages/_app';
import LinkWrapper from './Link';

interface Props {
    setMenuOpened: Function;
    menuItems?: NavigationSection[];
}

const Menu: React.FC<Props> = (props) => {
    return (
        <div className={styles['menu-modal']}>
            <div className={styles['menu-wrapper']}>
                <div className="content-container">
                    <nav className={`${styles['menu-container']} _3-col`}>
                        {
                            props.menuItems ? 
                                props.menuItems.map((menuItem, idx) => {
                                    if (!menuItem.FooterOnly) {
                                        return (
                                            <CSSTransition
                                                in
                                                appear
                                                enter
                                                classNames="column-fade"
                                                timeout={1000 * (idx + 1) * 500}
                                                key={`menu-${idx}`}
                                            >
                                                <div className="col">
                                                    <div className={styles['menu-sub-title-container']}>
                                                        <b>
                                                            <Typer text={menuItem.Title} delay={60} />
                                                        </b>
                                                    </div>
                                                    <Splitter />
                                                    <div className={styles['menu-sub-containers']}>
                                                        {
                                                            menuItem.NavItem.length > 0 ? menuItem.NavItem.map((link, j) => {
                                                                //const isDynamic = link.IsDynamicPage ? '/base' : '';
                                                                //const href = menuItem.Slug ? `${isDynamic}${menuItem.Slug}${link.Slug}` : `${isDynamic}${link.Slug}`;
                                                                const href = menuItem.Slug ? `${menuItem.Slug}${link.Slug}` : `${link.Slug}`;
                                                                if (href && !link.FooterOnly) {
                                                                    return (
                                                                        <div className={styles['menu-sub-container']} key={`menuitem-navitem-${j}`}>
                                                                            <LinkWrapper href={href} classNames='h2 link'>
                                                                                <span onClick={() => props.setMenuOpened(false)}>{link.Title}</span>
                                                                            </LinkWrapper>
                                                                            {link.Description ? <p>{link.Description}</p> : null}
                                                                        </div>
                                                                    )
                                                                }
                                                                return null
                                                            }) : null
                                                        }
                                                    </div>
                                                </div>
                                            </CSSTransition>
                                        )
                                    }
                                    return null
                                })
                            : null
                        }
                    </nav>
                </div>
            </div>
        </div>
    )
};

export default Menu;