import qs from "qs";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL(path?: string): string {
    path = path ? path : '';
    const urlOption1 = process.env.NEXT_PUBLIC_STRAPI_API_URL;
    const urlOption2 = publicRuntimeConfig.NEXT_PUBLIC_STRAPI_API_URL;
    return `${urlOption1 || urlOption2}${path}`;
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI(path: string, urlParamsObject = {}, options = {}) {
    
    // Merge default and user options
    const mergedOptions: any = process.env.STRAPI_API_KEY ? {
        headers: {
            "Authorization": `Bearer ${process.env.STRAPI_API_KEY}`,
            "Content-Type": "application/json",
        },
        ...options,
    } : {
        headers: {
            "Content-Type": "application/json"
        },
        ...options,
    };

    // Build request URL
    const queryString = qs.stringify(urlParamsObject);
    const requestUrl = `${getStrapiURL(
        `/api${path}${queryString ? `?${queryString}` : ""}`
    )}`;

    const data = await fetch(requestUrl, mergedOptions).then((response) => {
        if (response.ok) {
            return response.json();
        }
        console.error(response.statusText);
        return null
    })
    .then((responseJson) => {
        return responseJson
    })
    .catch(() => {
        console.error('Error in parsing json')
        return null
    });

    return data
}