import React from 'react';
import styles from './Header.module.scss';
import Logo from './assets/kayhanspacelogo.svg';
import AnimatedLogo from './assets/animatedlogo.svg';
import MenuIcon from './assets/menu.svg';
import NewTabIcon from "./assets/new-tab.svg";
import Button from './Button';
import Menu from './Menu';
import ExitIcon from './assets/exit.svg';
import LightIcon from './assets/light.svg';
import DarkIcon from './assets/dark.svg';
import { CSSTransition } from "react-transition-group";
import Link from 'next/link';
import { MenuStateContext, MenuStateContextType, NavigationSection, ScrollPosContext, ScrollPosContextType } from '../pages/_app';
import throttle from "lodash/throttle";
import LinkWrapper from './Link';


interface Props {
    setModeClassName: Function;
    modeClassName: string;
    navigation?: NavigationSection[]
    ctaUrl: string;
    ctaTitle: string;
}

const Header: React.FC<Props> = (props) => {
    const { scrollPos } = React.useContext(ScrollPosContext) as ScrollPosContextType;
    const { menuOpened, setMenuOpened } = React.useContext(MenuStateContext) as MenuStateContextType;
    const [scrolled, setScrolled] = React.useState(scrollPos > 32 ? true : false);

    const handleLogoResize = throttle(() => {
        setScrolled(scrollPos > 32);
    }, 250);

    React.useEffect(() => {
        handleLogoResize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    React.useEffect(() => {
        handleLogoResize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollPos]);

    React.useEffect(() => {
        if(menuOpened){
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'initial';
        }
    }, [menuOpened]);

    return(
        <>  
            {
                props.navigation ? 
                    <CSSTransition
                        in={menuOpened}
                        appear={menuOpened}
                        enter={menuOpened}
                        unmountOnExit
                        classNames="menu-fade"
                        timeout={300}
                    >

                        <Menu setMenuOpened={setMenuOpened} menuItems={props.navigation} />
                    </CSSTransition> : null
            }
            <div className={`${styles['mobile-header-wrapper']}`}>
                <div className={styles['mobile-header-container']}>
                    <div className={styles['mobile-header-section']}>
                        <Link href="/">
                            <a className={styles['logo-link']} onClick={() => setMenuOpened(false)} aria-label="Link to the home page" title="Link to the home page">
                                <Logo className={styles['header-logo']} role="img" />
                            </a>
                        </Link>
                    </div>
                    <div className={`${styles['mobile-header-section']} ${styles['mobile-header-actions']}`}>
                        <div className={styles['mobile-header-action']}>
                            {
                                props.ctaUrl ? 
                                <LinkWrapper
                                    text={props.ctaTitle}
                                    href={props.ctaUrl}
                                    classNames='light'
                                    trackConversion
                                /> : null
                            }
                        </div>
                        <div className={styles['mobile-header-action']}><Button label={props.modeClassName === 'light' ? 'Turn on dark mode' : 'Turn off dark mode'} noBorder type='header' renderIcon={props.modeClassName === 'light' ? <DarkIcon /> : <LightIcon />} callback={() => props.setModeClassName(props.modeClassName === 'light' ? 'dark' : 'light')} /></div>
                        <div className={styles['mobile-header-action']}>
                            {
                                props.navigation ?
                                    <Button
                                        label={menuOpened ? 'Close menu' : 'Open menu'}
                                        noBorder
                                        type='header'
                                        renderIcon={menuOpened ? <ExitIcon /> : <MenuIcon />}
                                        callback={() => setMenuOpened(!menuOpened)}
                                    /> : null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.header} ${scrolled ? styles['scrolled'] : ''}`}>
                <div className="content-container">
                    <div className={styles['header-contents-container']}>
                        <div className={`${styles['header-logo-container']} ${scrolled ? styles['scrolled'] : ''}`} /*style={cssLogoStyles}*/>
                            <Link href="/">
                                <a className={styles['logo-link-square']} onClick={() => setMenuOpened(false)} aria-label="Link to the home page" title="Link to the home page">
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.header} ${styles['logo-wrapper']} ${scrolled ? styles['scrolled'] : ''}`}>
                <div className="content-container">
                    <div className={styles['header-contents-container']}>
                        <div className={`${styles['header-logo-container']} ${scrolled ? styles['scrolled'] : ''}`} /*style={cssLogoStyles}*/>
                            <Link href="/">
                                <a className={styles['logo-link']} onClick={() => setMenuOpened(false)} aria-label="Link to the home page" title="Link to the home page">
                                    <Logo /*style={{ height: cssLogoHeight }}*/ className={styles['header-logo']} role="img" />
                                    <AnimatedLogo /*style={{ height: cssLogoHeight }}*/ className={styles['animated-header-logo']} role="img" />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.header}>
                <div className="content-container">
                    <div className={styles['header-contents-container']}>
                        <div className={styles['header-menu-container']}>
                            <div>
                                {
                                    props.ctaUrl ? 
                                        <Button
                                            isLink
                                            isLinkWrapper
                                            openNewTab
                                            trackConversion
                                            renderIcon={<NewTabIcon />}
                                            href={props.ctaUrl}
                                            type='header'
                                            text={props.ctaTitle}
                                        />
                                    : null
                                }
                            </div>
                            <div>
                                <Button
                                    label={props.modeClassName === 'light' ? 'Turn on dark mode' : 'Turn off dark mode'}
                                    type='header'
                                    renderIcon={props.modeClassName === 'light' ? <DarkIcon /> : <LightIcon />}
                                    callback={() => props.setModeClassName(props.modeClassName === 'light' ? 'dark' : 'light')}
                                />
                            </div>
                            <div>
                                <Button
                                    label={menuOpened ? 'Close menu' : 'Open menu'}
                                    type='header'
                                    renderIcon={menuOpened ? <ExitIcon /> : <MenuIcon />}
                                    callback={() => setMenuOpened(!menuOpened)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Header;