import React from "react";
import buttonStyles from './Button.module.scss';
import Link from 'next/link';
import LinkWrapper from "./Link";

interface Props {
    type?: 'primary' | 'secondary' | 'white' | 'header';
    renderIcon?: JSX.Element;
    label?: string;
    text?: string;
    callback?: Function;
    isLink?: boolean;
    isLinkWrapper?: boolean;
    openNewTab?: boolean;
    href?: string;
    disabled?: boolean;
    noBorder?: boolean;
    isSubmit?: boolean;
    children?: React.ReactElement;
    classNames?: string;
    trackConversion?: boolean;
    noFollow?: boolean;
    stopPropagation?: boolean;
}

const Button: React.FC<Props> = (props) => {
    const type = props.isSubmit ? "submit" : "button";
    const href = props.href ? props.href : '#';
    let classNames = `${buttonStyles.button} kss-button`;
    if (props.noBorder) classNames += ` ${buttonStyles['no-border']}`;
    if (props.classNames) classNames += ` ${props.classNames}`;
    if (props.type === 'header') classNames += ` ${buttonStyles.header}`;
    if (props.type === 'secondary') classNames += ` ${buttonStyles.secondary}`;
    if (props.type === 'white') classNames += ` ${buttonStyles.white}`;
    if (props.renderIcon && (!props.text && !props.children )) classNames += ` ${buttonStyles.icon}`;
    if (props.disabled) classNames += ` disabled`;

    const contents = <>
        {props.text ? <span className={buttonStyles['button-content']}>{props.text}</span> : null}
        {props.children ? <div className={buttonStyles['button-content']}>{props.children}</div> : null}
        {props.renderIcon ? <div className={`button-icon ${buttonStyles['button-icon']}`}>{props.renderIcon}</div> : null}
    </>;

    return (
            props.isLink ? 
                props.isLinkWrapper ? 
                    <LinkWrapper
                        customStyles
                        classNames={classNames}
                        href={href}
                        noFollow={props.noFollow}
                        label={props.text ? props.text : props.label}
                        trackConversion={props.trackConversion}
                    >
                        {contents}
                    </LinkWrapper> :
                    <Link href={href}>
                        <a
                            className={classNames}
                            aria-label={props.text ? props.text : props.label}
                            title={props.text ? props.text : props.label}
                        >
                            {contents}
                        </a>
                    </Link>
            :
            <button
                role='button'
                title={props.text ? props.text : props.label}
                aria-label={props.text ? props.text : props.label}
                disabled={ props.disabled ? true : false }
                className={classNames}
                onClick={(e) => { 
                    if (props.callback) {
                        if (props.stopPropagation) {
                            e.stopPropagation();
                        }
                        props.callback();
                    }
                }}
                type={type}>
                {contents}
            </button>
        
    )
};

export default Button;