import React from 'react';
import { CSSTransition } from 'react-transition-group';
import VisibilitySensor from 'react-visibility-sensor';
import Image, { StaticImageData } from 'next/image';
import ExitIcon from './assets/exit.svg';
import MagnifyIcon from './assets/magnify.svg';
import { ThemeContext, ThemeContextType } from '../pages/_app';
import Button from './Button';
import Router from 'next/router';

interface Props {
    src: string | StaticImageData;
    classNames?: string;
    alt: string;
    width?: number;
    height?: number;
    vizTrigger?: boolean;
    fill?: true;
    shouldFade?: boolean;
    zoomable?: boolean;
    href?: string;
}

const FancyImage: React.FC<Props> = (props) => {
    let setLockScroll: undefined | ((bool: boolean) => void);
    const themeContext = React.useContext(ThemeContext) as ThemeContextType;
    if (themeContext) {
        setLockScroll = themeContext.setLockScroll;
    }
    let classNames = props.shouldFade ? "image-fade-loader image-loader" : "image-loader";
    const [visible, setVisible] = React.useState(props.vizTrigger ? false : true);
    const [loaded, setLoaded] = React.useState(false);
    const [isZoomed, setIsZoomed] = React.useState(false);
    if (props.zoomable) classNames += ` zoomable-media`;
    if (props.href) classNames += ` link-image`;
    if (props.classNames) classNames += ` ${props.classNames}`;

    React.useEffect(() => {
        if (setLockScroll) {
            setLockScroll(isZoomed);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isZoomed]);

    React.useEffect(() => {
        return () => {
            setIsZoomed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contents = <CSSTransition
        enter={visible}
        in={visible && loaded}
        appear={visible}
        classNames="image"
        timeout={750}
    >
        <div
            className={classNames}
            onClick={() => {
                if (props.zoomable) {
                    setIsZoomed(true);
                }
                if (props.href) {
                    Router.push(props.href);
                }
            }}
        >
            {props.zoomable ? <Button label='Button to zoom image' classNames='zoom-indicator' renderIcon={<MagnifyIcon />} type="secondary" /> : null }
            <div className="image-loader" />
            { props.shouldFade ? null : <div className="image-slug" /> }
            <div className="image-container">
                <Image
                    src={props.src}
                    alt={props.alt ? props.alt : 'Caption for image is missing'}
                    height={props.height}
                    width={props.width}
                    layout={props.fill ? 'fill' : undefined}
                    onLoad={() => setLoaded(true)}
                />
            </div>
        </div>
    </CSSTransition>;

    return (
        <>
            { props.vizTrigger ?
            <VisibilitySensor
                onChange={(isVisible: boolean) => {
                    if (isVisible && !visible) {
                        setVisible(true);
                    }
                }}
                partialVisibility={true}
            >
                {contents}
            </VisibilitySensor> : contents }
            { isZoomed ?
                <div className="zoomed-media-container">
                    <div className="zoomed-media-controls-container">
                        <Button
                            label={'Close zoomed-in image'}
                            type='secondary'
                            renderIcon={<ExitIcon />}
                            callback={() => setIsZoomed(false)}
                        />
                    </div>
                    <div className="zoomed-media-content">
                        <Image
                            src={props.src}
                            alt={props.alt ? props.alt : 'Caption for image is missing'}
                            layout={'fill'}
                            onLoad={() => setLoaded(true)}
                        />
                    </div>
                </div>
            : null}
        </>
    )
};

export default FancyImage;

